.reset-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 7px;
  background: transparent;
  cursor: pointer;
  &:hover {
    background: transparent;
  }
}

.reset-icon {
  border: none;
  background: transparent;
}

.reset-text {
  font-size: 12px;
}
