.chat-modal {
  position: fixed;
  left: -390px;
  top: 0;
  width: 390px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease;
  z-index: 1001;

  &.show {
    left: 0;
  }

  .chat-modal-content {
    padding: 20px;
    height: 100%;
    overflow-y: auto;

    button {
      margin-bottom: 20px;
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }

    .profile-section {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease, transform 0.3s ease;
      cursor: pointer;

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
        border: 2px solid #007bff;
      }

      .profile-info {
        h2 {
          margin: 0;
          font-size: 1.2em;
          color: #333;
        }

        p {
          margin: 0;
          color: gray;
        }
      }
    }
  }
}
