.search-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #cbd2e0;
  border-radius: 7px;
  padding: 5px 10px;
  width: 100%;
  .search-input {
    border: none;
    outline: none;
    flex-grow: 1;
  }
  .search-button {
    background: none;
    border: none;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
