.bg-gray-100 {
  background-color: #f8f9fa;
}

.card-green-bg {
  background-color: #d9fcf6 !important;
  border: transparent !important;
}

.card-blue-bg {
  background-color: #cff1ff !important;
  border: transparent !important;
}

.responsive-text {
  font-size: 2rem;
}

/* Smaller devices (phones, 375px and down) */
@media (max-width: 375px) {
  .responsive-text {
    font-size: 1.2rem;
  }

  .card-text-sm {
    font-size: 0.75rem;
  }

  .plane-img,
  .profile-img {
    width: 100px;
    height: auto;
  }
}

/* Small devices (phones, 576px and down) */
@media (min-width: 376px) and (max-width: 576px) {
  .responsive-text {
    font-size: 1.5rem;
  }

  .card-text-sm {
    font-size: 0.875rem;
  }

  .plane-img,
  .profile-img {
    width: 120px;
    height: auto;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 577px) and (max-width: 768px) {
  .responsive-text {
    font-size: 1.75rem;
  }

  .card-text-sm {
    font-size: 1rem;
  }

  .plane-img,
  .profile-img {
    width: 134px;
    height: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 769px) and (max-width: 992px) {
  .responsive-text {
    font-size: 1.875rem;
  }

  .card-text-sm {
    font-size: 1.125rem;
  }

  .plane-img,
  .profile-img {
    width: 150px;
    height: auto;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 993px) {
  .responsive-text {
    font-size: 2rem;
  }

  .card-text-sm {
    font-size: 1.25rem;
  }

  .plane-img,
  .profile-img {
    width: 170px;
    height: auto;
  }
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  font-family: sans-serif;
}

.back-title {
  font-size: 1.5em;
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: start;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: none;
}

.flip-card-back {
  position: absolute;
  text-align: center;
  justify-content: center;
  display: flex;
  width: 90%;
  height: 90%;
  padding-top: 10%;
  transform: rotateY(180deg);
}
