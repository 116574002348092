.role-select-page {
  .container {
    text-align: center;
    padding-top: 50px;
  }

  .card {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    display: inline-block;
    text-align: left;
    width: 100%;
  }

  .progress {
    background-color: #ddd;
    border-radius: 4px;
    height: 10px;
    position: relative;
  }

  .progress-bar {
    background-color: #00bcd4;
    height: 100%;
    border-radius: 4px;
    transition: width 0.3s;
  }

  .list-group-item {
    border: 1px solid #ddd !important;
    padding: 10px;
    border-radius: 4px;

    &:hover {
      background-color: #f8f9fa;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}
