.unique-container {
  background-color: #ffffff;
  display: flex;
  width: 270px;
  height: 120px;
  position: relative;
  border-radius: 6px;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);

    .unique-left-side {
      width: 100%;
    }

    .unique-card {
      animation: slide-top 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
    }

    .unique-post {
      animation: slide-post 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    }

    .unique-dollar {
      animation: fade-in-fwd 0.3s 1s backwards;
    }
  }

  @media only screen and (max-width: 480px) {
    transform: scale(0.7);

    &:hover {
      transform: scale(0.74);
    }
  }
}

.unique-left-side {
  background-color: #5de2a3;
  width: 130px;
  height: 120px;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  flex-shrink: 0;
  overflow: hidden;
}

.unique-right-side {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;

  &:hover {
    background-color: #f9f7f9;
  }
}

.unique-arrow {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.unique-new {
  font-size: 23px;
  font-family: "Lexend Deca", sans-serif;
  margin-left: 20px;

  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
}

.unique-card {
  width: 70px;
  height: 46px;
  background-color: #c7ffbc;
  border-radius: 6px;
  position: absolute;
  display: flex;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  box-shadow: 9px 9px 9px -2px rgba(77, 200, 143, 0.72);

  .unique-card-line {
    width: 65px;
    height: 13px;
    background-color: #80ea69;
    border-radius: 2px;
    margin-top: 7px;
  }
}

.unique-buttons {
  width: 8px;
  height: 8px;
  background-color: #379e1f;
  box-shadow: 0 -10px 0 0 #26850e, 0 10px 0 0 #56be3e;
  border-radius: 50%;
  margin-top: 5px;
  transform: rotate(90deg);
  margin: 10px 0 0 -30px;
}

.unique-post {
  width: 63px;
  height: 75px;
  background-color: #dddde0;
  position: absolute;
  z-index: 11;
  bottom: 10px;
  top: 120px;
  border-radius: 6px;
  overflow: hidden;

  .unique-post-line {
    width: 47px;
    height: 9px;
    background-color: #545354;
    position: absolute;
    border-radius: 0px 0px 3px 3px;
    right: 8px;
    top: 8px;

    &:before {
      content: "";
      position: absolute;
      width: 47px;
      height: 9px;
      background-color: #757375;
      top: -8px;
    }
  }

  .unique-screen {
    width: 47px;
    height: 23px;
    background-color: #ffffff;
    position: absolute;
    top: 22px;
    right: 8px;
    border-radius: 3px;

    .unique-dollar {
      position: absolute;
      font-size: 16px;
      font-family: "Lexend Deca", sans-serif;
      width: 100%;
      left: 0;
      top: 0;
      color: #4b953b;
      text-align: center;
    }
  }

  .unique-numbers {
    width: 12px;
    height: 12px;
    background-color: #838183;
    box-shadow: 0 -18px 0 0 #838183, 0 18px 0 0 #838183;
    border-radius: 2px;
    position: absolute;
    transform: rotate(90deg);
    left: 25px;
    top: 52px;
  }

  .unique-numbers-line2 {
    width: 12px;
    height: 12px;
    background-color: #aaa9ab;
    box-shadow: 0 -18px 0 0 #aaa9ab, 0 18px 0 0 #aaa9ab;
    border-radius: 2px;
    position: absolute;
    transform: rotate(90deg);
    left: 25px;
    top: 68px;
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-70px) rotate(90deg);
  }

  60% {
    transform: translateY(-70px) rotate(90deg);
  }

  100% {
    transform: translateY(-8px) rotate(90deg);
  }
}

@keyframes slide-post {
  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-70px);
  }
}

@keyframes fade-in-fwd {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
