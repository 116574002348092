.cancel-btn {
  height: 40px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  font-size: 15px;
  line-height: 1.4em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: gray;
  border: none;
  border-radius: 0.6em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background: gray;
}

.cancel-btn,
.cancel-btn span {
  transition: 200ms;
}

.cancel-btn .text {
  transform: translateX(-20px);
  color: white;
  font-weight: bold;
}

.cancel-btn .icon {
  position: absolute;
  border-left: 1px solid #888;
  transform: translateX(30px);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-btn svg {
  width: 15px;
  height: 15px;
  fill: #eee;
}

.cancel-btn:hover {
  background: darkgray;
}

.cancel-btn:hover .text {
  color: transparent;
}

.cancel-btn:hover .icon {
  width: 90px;
  border-left: none;
  transform: translateX(0px);
}

.cancel-btn:focus {
  outline: none !important;
}

.cancel-btn:active .icon svg {
  transform: scale(0.8);
}

.write-btn {
  height: 40px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  font-size: 15px;
  line-height: 1.4em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1db5be;
  border: none;
  border-radius: 0.6em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.write-btn,
.write-btn span {
  transition: 200ms;
}

.write-btn .text {
  transform: translateX(-20px);
  color: white;
  font-weight: bold;
}

.write-btn .icon {
  position: absolute;
  border-left: 1px solid #888;
  transform: translateX(30px);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.write-btn svg {
  width: 15px;
  height: 15px;
  fill: #eee;
}

.write-btn:hover {
  background: #17a2b8;
}

.write-btn:hover .text {
  color: transparent;
}

.write-btn:hover .icon {
  width: 90px;
  border-left: none;
  transform: translateX(0);
}

.write-btn:focus {
  outline: none;
}

.write-btn:active .icon svg {
  transform: scale(0.8);
}
