.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo-text {
  color: #1db5be;
  font-size: 2rem;
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
}
