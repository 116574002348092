.reviews-container {
  .scroll-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scroll-container::-webkit-scrollbar {
    display: none;
  }

  .review-card-container {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 16px;
  }

  .review-card {
    height: 100%;
  }

  .user-icon {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 8px;
  }

  .rating {
    font-size: 1.2em;
    color: #f39c12;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
