.search-navbar {
  overflow-x: auto; /* Initially hide overflow */
  white-space: nowrap; /* Prevent line breaks */
  display: flex;
  flex-wrap: nowrap; /* Ensure items stay on a single line */
  background: white !important;
}

.search-input-container {
  display: flex;
  align-items: center;
}

.search-input {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.search-button {
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}
