.progress-bar-container {
  .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
    overflow: hidden;
    .progress {
      height: 100%;
      background-color: #1db5be;
      transition: width 0.5s ease; // 이 부분은 제거해도 됩니다.
    }
  }
  .progress-info {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 10px;
  }
}
