.social-login {
  margin-top: 50px; // 상단 여백 추가
  .social-button {
    width: 100%; /* Ensure buttons take full width */
    padding: 0;
    border: 1px solid #ddd; /* Light border to match Google's button style */
  }
  .google-login-btn,
  .naver-login-btn {
    width: 100%; /* Ensure image takes full width of the button */
    height: auto;
  }
  .google-button {
    background-color: #ffffff; /* White background for Google button */
    padding: 0; /* Remove padding for the button to fit the image perfectly */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; /* Set a fixed height for the button */
    &:hover,
    &:active,
    &:focus {
      background-color: #ffffff !important; /* Ensure background remains white on hover, click, and focus */
      border-color: #ddd !important; /* Ensure border remains the same on hover, click, and focus */
      box-shadow: none !important; /* Remove box shadow on focus */
    }
  }
  .naver-button {
    background-color: #03c75a; /* Naver Green */
    padding: 0; /* Remove padding for the button to fit the image perfectly */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; /* Set a fixed height for the button */
    border: none; /* Remove default border */
    &:hover,
    &:active,
    &:focus {
      background-color: #03c75a !important; /* Ensure background remains green on hover, click, and focus */
      box-shadow: none !important; /* Remove box shadow on focus */
    }
  }
  .google-button img,
  .naver-button img {
    max-height: 100%;
    width: auto;
  }
  .text-center {
    padding: 20px;
    background-color: #fff; // 배경색 설정
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // 그림자 효과 추가
    border-radius: 8px; // 모서리 둥글게

    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .social-button {
      width: 100%; // 버튼 너비를 컨테이너에 맞춤
      font-size: 16px; // 글꼴 크기 설정
    }
  }
}
