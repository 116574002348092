.mentor-profile-card {
  margin: 10px;
  border-radius: 10px;
  border: 2px solid lightgray;
  background-color: white;
  width: 100%; /* Ensure it takes the full width of its container */
  max-width: 300px; /* Optionally set a max-width if needed */
  overflow: hidden; /* This ensures any overflowing text is hidden */

  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
}

.find-mentor-box {
  display: flex;
  align-items: flex-start;
  background-color: white;
  border: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.userinformation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
}

.user-info,
.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word; /* Allow long words to break and wrap onto the next line */
}

.user-name {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-info div,
.user-details div {
  font-size: 13px;
  margin-bottom: 2px;
  word-wrap: break-word; /* Allow long words to break and wrap onto the next line */
  overflow-wrap: break-word; /* Break long words */
}
.badge {
  font-size: 0.9rem;
  margin-right: 5px;
  margin-bottom: 5px;
}
