h2 {
  font-size: 2rem;
  font-weight: bold;
}

h5 {
  color: #28a745;
  font-weight: bold;
}

.list {
  background: #f8f9fa;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 0.5rem 0;
  font-size: clamp(0.875rem, 1.2vw, 1rem);
}
.m-0 {
  margin: 0;
}

.p-5 {
  padding: 3rem;
}

.text-center {
  text-align: center;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}
.text-center {
  text-align: center;
}
