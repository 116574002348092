.custom-input-group {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 7px;
  overflow: hidden;

  .custom-input-display {
    flex: 1;
    padding: 10px;
    background-color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    &.placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
    &.selected {
      color: black;
    }
  }
  .custom-input-button {
    border: none;
    background-color: #fff;
    padding: 10px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.input-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}
.input-subtitle {
  font-size: 14px;
  margin-bottom: 10px;
}
