.edit-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 40px;
  border: none;
  padding: 0px 20px;
  background-color: rgb(168, 38, 255);
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 5px 5px 0px rgb(140, 32, 212);
  transition-duration: 0.3s;

  &-svg {
    width: 13px;
    position: absolute;
    right: 0;
    margin-right: 20px;
    fill: white;
    transition-duration: 0.3s;
  }

  &:hover {
    color: transparent;

    &-svg {
      right: 43%;
      margin: 0;
      padding: 0;
      border: none;
      transition-duration: 0.3s;
    }
  }

  &:active {
    transform: translate(3px, 3px);
    transition-duration: 0.3s;
  }
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}
